<template>
  <div class="container">
    <div class="oilSpreadBarChart" ref="oilSpreadBarChart"></div>
    <div class="chartValue">
      <p v-for="(item, index) in deviceInfo.device_count" :key="index">
        {{ item }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OilSpreadBarChart',
  props: {
    deviceInfo: Object
  },
  data: () => {
    return {
      chartInstance: null,
      rankIcon: {
        first: require('assets/img/common/rank-icon1.png'),
        second: require('assets/img/common/rank-icon2.png'),
        third: require('assets/img/common/rank-icon3.png'),
        fourth: require('assets/img/common/rank-icon4.png'),
        fifth: require('assets/img/common/rank-icon5.png')
      }
    }
  },
  watch: {
    deviceInfo() {
      this.updateChart()
    }
  },
  methods: {
    // 初始化echartInstance对象
    initChart() {
      this.chartInstance = this.$echarts.init(this.$refs.oilSpreadBarChart)
      this.chartShowLoading()
      this.updateChart()
    },
    // 更新图表
    updateChart() {
      const option = {
        grid: {
          height: '88%',
          top: '27px',
          left: '15px',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        tooltip: {},
        xAxis: {
          type: 'value',
          show: false
        },
        yAxis: {
          type: 'category',
          inverse: true,
          data: this.deviceInfo.area,
          axisLabel: {
            align: 'left',
            margin: 20,
            padding: -74,
            textStyle: {
              color: '#fff',
              fontSize: 14
            },

            rich: {
              first: {
                width: 26,
                height: 18,
                backgroundColor: {
                  image: this.rankIcon.first
                }
              },
              second: {
                width: 26,
                height: 18,
                backgroundColor: {
                  image: this.rankIcon.second
                }
              },
              third: {
                width: 26,
                height: 18,
                backgroundColor: {
                  image: this.rankIcon.third
                }
              },
              fourth: {
                width: 26,
                height: 18,
                backgroundColor: {
                  image: this.rankIcon.fourth
                }
              },
              fifth: {
                width: 26,
                height: 18,
                backgroundColor: {
                  image: this.rankIcon.fifth
                }
              }
            },
            formatter: (value, index) => {
              const richName = this.indexToRankIconName(index)
              return '{' + richName + '|}  ' + value
            }
          },
          axisTick: { show: false },
          axisLine: { show: false }
        },
        series: [
          {
            name: '设备数',
            type: 'bar',
            data: this.deviceInfo.device_count,
            barWidth: 12,
            barGap: 29, // 圆柱的间距
            itemStyle: {
              normal: {
                barBorderRadius: 6, // 圆角
                color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    // 背景渐变色
                    offset: 0,
                    color: 'rgba(0, 192, 255, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(6, 116, 253, 1)'
                  }
                ])
              }
            }
            // showBackground: true, // 显示背景色
            // backgroundStyle: {
            //   color: '#17226B' // 设置背景色
            // }
          }
        ]
      }
      this.chartInstance.hideLoading()
      this.chartInstance.setOption(option)
    },
    // 根据index查找rankIconName
    indexToRankIconName(index) {
      let name = ''
      switch (index) {
        case 0:
          name = 'first'
          break
        case 1:
          name = 'second'
          break
        case 2:
          name = 'third'
          break
        case 3:
          name = 'fourth'
          break
        case 4:
          name = 'fifth'
          break
        default:
          name = ''
      }
      return name
    },
    // 图表showLoading
    chartShowLoading() {
      this.chartInstance.showLoading({
        text: 'loading',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
    },
    // 图表自适应
    chartResize() {
      window.onresize = () => {
        this.$echarts
          .init(document.getElementsByClassName('OilSpreadBarChart'))
          .resize()
      }
    }
  },
  mounted() {
    this.initChart()
  },
  created() {}
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
.container {
  width: 380px;
  height: 237px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background: rgba(23, 78, 128, 0.22);
  .oilSpreadBarChart {
    width: 308px;
    height: 237px;
  }
  .chartValue {
    margin: 0px 15px 0 0;
    max-width: 52px;
    height: 208px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    p {
      max-width: 52px;
      height: 11px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #fff;
    }
  }
}
</style>
