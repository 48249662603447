<template>
  <div class="container">
    <div class="oilRateBarChart" ref="oilRateBarChart"></div>
    <div class="chartValue">
      <p v-for="(item, index) in deviceInfo.rate" :key="index">{{ item }}%</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OilRateBarChart',
  props: {
    deviceInfo: Object
  },
  data: () => {
    return {
      chartInstance: null
    }
  },
  watch: {
    deviceInfo() {
      this.updateChart()
    }
  },
  methods: {
    // 初始化echartInstance对象
    initChart() {
      this.chartInstance = this.$echarts.init(this.$refs.oilRateBarChart)
      this.chartShowLoading()
      this.updateChart()
    },
    updateChart() {
      const option = {
        grid: {
          height: '100%',
          top: '12px',
          left: '15px',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        tooltip: {},
        xAxis: {
          type: 'value',
          show: false,
          max: 100
        },
        yAxis: {
          type: 'category',
          inverse: true,
          data: this.deviceInfo.area,
          axisLabel: {
            align: 'left',
            margin: 20,
            padding: -54,
            textStyle: {
              color: '#fff',
              fontSize: 12
            },

            rich: {
              odd: {
                color: '#00EAFF',
                fontFamily: 'SourceHanSansCN-Bold',
                fontSize: 14,
                fontWeight: 'bold'
              },
              even: {
                color: '#0084FF',
                fontFamily: 'SourceHanSansCN-Bold',
                fontSize: 14,
                fontWeight: 'bold'
              }
            },
            formatter: (value, index) => {
              const richName = this.indexToRichName(index + 1)
              return '{' + richName + '|' + (index + 1) + '}  ' + value
            }
          },
          axisTick: { show: false },
          axisLine: { show: false }
        },
        series: [
          {
            name: this.deviceInfo.type,
            type: 'bar',
            data: this.deviceInfo.rate,
            barWidth: 7,
            barCategoryGap: 24, // 同系列圆柱的间距
            itemStyle: {
              normal: {
                barBorderRadius: 4, // 圆角
                color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    // 背景渐变色
                    offset: 0,
                    color: '#3796FD'
                  },
                  {
                    offset: 1,
                    color: '#509DEF'
                  }
                ])
              }
            },
            showBackground: true, // 显示背景色
            backgroundStyle: {
              color: '#0070CE' // 设置背景色
            }
          }
        ]
      }
      this.chartInstance.hideLoading()
      this.chartInstance.setOption(option)
    },
    // 根据index查找richName
    indexToRichName(index) {
      return index % 2 == 0 ? 'even' : 'odd'
    },
    // 图表showLoading
    chartShowLoading() {
      this.chartInstance.showLoading({
        text: 'loading',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
    },
    // 图表自适应
    chartResize() {
      window.onresize = () => {
        this.$echarts
          .init(document.getElementsByClassName('oilRateBarChart'))
          .resize()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  created() {}
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
.container {
  margin-top: 14px;
  width: 386px;
  height: 170px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background: rgba(23, 78, 128, 0.22);
  .oilRateBarChart {
    width: 295px;
    height: 170px;
  }
  .chartValue {
    margin-right: 15px;
    width: 45px;
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    p {
      width: 45px;
      height: 11px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #fff;
    }
  }
}
</style>
