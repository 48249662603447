<template>
  <div class="machineWarningTable">
    <oil-title>
      <span slot="left">故障报警信息</span>
    </oil-title>
    <div class="tableBg">
      <el-table
        ref="table"
        :data="alarmInfo"
        :header-cell-style="headerCellStyle"
        :cell-style="cellStyle"
        :row-class-name="tableRowClassName"
      >
        <el-table-column label="名称" min-width="118">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p>{{ scope.row.device_name }}</p>
              <div slot="reference" class="name-wrapper">
                {{ scope.row.device_name }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="时间" min-width="100">
        </el-table-column>
        <el-table-column label="风险预警" min-width="63">
          <template slot-scope="scope">
            <p :style="getWarningStyle(scope.row.state)">
              {{ scope.row.state }}
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import OilTitle from '../oilTitle/OilTitle.vue'
export default {
  name: 'MachineWarningTable',
  props: {
    alarmInfo: Array,
    alarmPx: Number
  },
  components: { OilTitle },
  data: () => {
    return {
      clearAlarmTime: null,
      ypx: 0
    }
  },
  watch: {
    alarmPx: {
      handler: function () {
        clearInterval(this.clearMessageTime)
        if (this.alarmPx) {
          this.tablleAutoScroll()
        }
      },
      deep: true
    }
  },
  methods: {
    /**
     *  其它方法
     */
    tablleAutoScroll() {
      this.clearAlarmTime = setInterval(() => {
        this.ypx++
        if (this.ypx > this.alarmPx) {
          this.ypx = 0
        }
        this.$refs.table.bodyWrapper.childNodes[0].childNodes[1].style.transform =
          'translateY(-' + this.ypx + 'px)'
      }, 200)
    },
    // table隔行样式
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return 'color-row'
      }
    },
    headerCellStyle() {
      return {
        height: '36px',
        'text-align': 'center',
        'font-size': '14px',
        'font-family': 'Source Han Sans CN',
        color: '#FFFFFF',
        background: '#1D5480'
      }
    },
    cellStyle() {
      return 'text-align:center'
    },
    // 根据warning设置字体颜色
    getWarningStyle(value) {
      let cor = ''
      switch (value) {
        case '故障':
          cor = '#FF3232'
          break
        case '已解决':
          cor = '#27E54A'
          break
        case '未解决':
          cor = '#FFC600'
          break
        default:
          cor = '#fff'
      }
      return { color: cor }
    }
  },
  mounted() {},
  created() {},
  destroyed() {
    clearInterval(this.clearAlarmTime)
  }
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
.machineWarningTable {
  margin-top: 17px;
  width: 386px;
  display: flex;
  flex-direction: column;
  align-content: center;
  .tableBg {
    width: 386px;
    margin-top: 14px;
    height: 303px;
    background: rgba(23, 78, 128, 0.22);
  }
  //table 表格start
  ::v-deep {
    .el-table {
      width: 386px;
      height: 303px;
      &::before {
        height: 0px;
      }
      & th:nth-child(3) > .cell {
        color: #ffc600;
      }
      table th,
      table td {
        padding: 0px !important;
        border-bottom: none !important;
      }
      .el-table__row {
        td {
          width: 100%;
          height: 12px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(123, 188, 238, 1);
        }
      }
    }
    .el-table,
    .el-table__expanded-cell {
      background-color: transparent;
    }
    .el-table tr {
      background-color: transparent !important;
    }
    .el-table--enable-row-transition .el-table__body td,
    .el-table .cell {
      padding: 0px 2px !important;
      height: 38px;
      line-height: 38px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      background-color: transparent;
    }
    .el-table .color-row {
      background: rgba(4, 40, 72, 0.8) !important;
    }
  }
}
</style>
