<template>
  <div class="container">
    <div class="realtimeOilProdChart" ref="realtimeOilProdChart"></div>
    <div class="chartValue">
      <p v-for="(item, index) in deviceInfo.T_Production" :key="index">
        {{ item }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RealtimeOilProdChart',
  props: {
    deviceInfo: Object
  },
  data: () => {
    return {
      chartInstance: null,
      symbol: 'image://' + '~assets/img/common/chart-point.png'
    }
  },
  watch: {
    deviceInfo() {
      this.updateChart()
    }
  },
  methods: {
    // 初始化echartInstance对象
    initChart() {
      this.chartInstance = this.$echarts.init(this.$refs.realtimeOilProdChart)
      this.chartShowLoading()
      this.updateChart()
    },
    updateChart() {
      // const yArr = [200, 55, 77, 36.15, 44.88]
      const option = {
        grid: {
          height: '100%',
          top: '22px',
          left: '15px',
          right: '10px',
          bottom: '0%',
          containLabel: true
        },
        tooltip: {},
        xAxis: {
          type: 'value',
          show: false
        },
        yAxis: {
          type: 'category',
          inverse: true,
          data: this.deviceInfo.area,
          axisLabel: {
            show: false
          },
          axisTick: { show: false },
          axisLine: { show: false }
        },
        series: [
          {
            name: '',
            type: 'pictorialBar',
            symbol: this.symbol,
            symbolSize: [24, 24],
            symbolOffset: [10, 0],
            z: 12,
            itemStyle: {
              normal: {
                color: '#fff'
              }
            },
            data: this.getSymbolData(this.deviceInfo.T_Production)
          },
          {
            name: '实时采液量',
            type: 'bar',
            barWidth: 3,
            barCategoryGap: 35, // 同系列圆柱的间距
            itemStyle: {
              normal: {
                barBorderRadius: 6, // 圆角
                color: (params) => {
                  var colorlist = [
                    '#00EAFF',
                    '#0084FF',
                    '#00EAFF',
                    '#0084FF',
                    '#00EAFF'
                  ]
                  return colorlist[params.dataIndex]
                }
              }
            },
            label: {
              normal: {
                color: '#fff',
                show: true,
                position: [0, '-20px'],
                textStyle: {
                  fontSize: 14
                },
                rich: {
                  odd: {
                    color: '#00EAFF',
                    fontFamily: 'SourceHanSansCN-Bold',
                    fontSize: 14,
                    fontWeight: 'bold'
                  },
                  even: {
                    color: '#0084FF',
                    fontFamily: 'SourceHanSansCN-Bold',
                    fontSize: 14,
                    fontWeight: 'bold'
                  }
                },
                formatter: (params) => {
                  const richName = this.indexToRichName(params.dataIndex + 1)
                  return (
                    '{' +
                    richName +
                    '|' +
                    (params.dataIndex + 1) +
                    '}   ' +
                    params.name
                  )
                }
              }
            },
            showBackground: true, // 显示背景色
            backgroundStyle: {
              color: '#0E2E4D' // 设置背景色
            },
            data: this.deviceInfo.T_Production
          }
        ]
      }
      this.chartInstance.hideLoading()
      this.chartInstance.setOption(option)
    },
    // 获取标志点
    getSymbolData(data) {
      const arr = []
      for (var i = 0; i < data.length; i++) {
        arr.push({
          value: data[i],
          symbolPosition: 'end'
        })
      }
      return arr
    },
    // 根据index查找richName
    indexToRichName(index) {
      return index % 2 == 0 ? 'even' : 'odd'
    },
    // 图表showLoading
    chartShowLoading() {
      this.chartInstance.showLoading({
        text: 'loading',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
    },
    // 图表自适应
    chartResize() {
      window.onresize = () => {
        this.$echarts
          .init(document.getElementsByClassName('realtimeOilProdChart'))
          .resize()
      }
    }
  },
  mounted() {
    this.initChart()
  },
  created() {}
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
.container {
  margin-top: 11px;
  width: 386px;
  height: 237px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background: rgba(23, 78, 128, 0.22);
  .realtimeOilProdChart {
    width: 295px;
    height: 215px;
  }
  .chartValue {
    margin-right: 20px;
    width: auto;
    height: 215px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    p {
      position: relative;
      width: auto;
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-style: italic;
      color: #ffffff;
      &::after {
        position: absolute;
        bottom: -5px;
        right: 0;
        content: '';
        display: inline-block;
        width: 14px;
        height: 2px;
        background: #00eaff;
      }
    }
  }
}
</style>
